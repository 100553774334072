import { useEffect, useState } from "react";
import FilterTable, { ITableFields } from "../../components/global/FilterTable";
import {ICohortDTO } from "../../interfaces/cohort";
import cohortService from "../../services/cohortService";
import authService from "../../services/authService";

const ListCohorts = () => {
  const [cohorts, setCohorts] = useState<ICohortDTO[]>([]);

  useEffect(() => {
    const token = authService.getAccessToken() || "";
    cohortService.getAll(token, (cohorts: ICohortDTO[]) => {
      setCohorts(cohorts);
    });
  }, []);

  const tableFields: ITableFields[] = [
    { label: "ID", field: "id", type: "string" },
    { label: "Name", field: "name", type: "string" },
    { label: "Start Date", field: "startDate", type: "date" },
    { label: "# Members", field: "numberOfMembers", type: "string" },
  ];

  return (
    <FilterTable
      title="👨‍🎓 Cohorts"
      viewLink={"/cohorts/"}
      rows={cohorts}
      fields={tableFields}
      createLink={`/cohorts/new`}
    />
  );
};

export default ListCohorts;
